<template>
  <div>
    <div class="header">
      <van-nav-bar
        :title="$t('TransactionDetails')"
        left-arrow
        @click-left="$router.go(-1)"
      >
      </van-nav-bar>
    </div>
    <div style="padding-top: 50px;">
      <div class="itemV">
        <span class="itemTv1">{{ $t("fbuy.orderno") }}</span>
        <span v-if="orderDetail.order_sn" class="itemTv2">{{ orderDetail.order_sn }}</span>
        <span v-else class="itemTv2">{{ orderDetail.orderno }}</span>
      </div>
      <div class="itemV">
        <span class="itemTv1">{{ $t("jilu.biz") }}</span>
        <span class="itemTv2">{{ orderDetail.product_name }}</span>
      </div>
      <div class="itemV">
        <span class="itemTv1">{{ $t("jilu.buy") }}</span>
        <span class="itemTv2">{{ orderDetail.total_price }}</span>
      </div>
      <div class="itemV">
        <span class="itemTv1">{{ $t("jilu.price") }}</span>
        <span class="itemTv2">{{ orderDetail.profit }}</span>
      </div>
      <div class="itemV">
        <span class="itemTv1">{{ $t("timetime") }}</span>
        <span class="itemTv2">{{ orderDetail.createtime | dateformat }}</span>
      </div>
      <div class="itemV">
        <span class="itemTv1"></span>
        <span v-if="orderDetail.status === '0'" class="itemTv2">{{ $t("jiao-yi-zhong") }}</span>
        <span v-else class="itemTv2">{{ $t("yi-wan-cheng") }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      orderDetail: "",
    };
  },
  created(e) {
    this.orderDetail = JSON.parse(this.$route.query.context);
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.itemV {
  display: flex;
  align-items: center;
  border-top: 0.1px solid #f7f8fa;
  height: 45px;
  padding-left: 1rem;
  padding-right: 1rem;
}
.itemTv1 {
  color: #9097a7;
  font-size: 15px;
}
.itemTv2 {
  color: #4d5260;
  font-size: 15px;
  flex: 1;
  text-align: end;
}
</style>
